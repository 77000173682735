@c-blue: #428bca;
@c-white: #000;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
body {
  background: url("/images/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 14px;
  line-height: 1.6em;
}

.content-container {
  max-width: 80%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  h1 {
    margin-bottom: 20px;
  }
  .nav-tabs {
    border-bottom: 1px solid @c-blue;
    margin: 10px 0;
    >li {
      >a:hover {
        border-color: #eee #eee @c-blue;
      }
    }
    >li.active {
      a,a:hover,a:focus {
        border: 1px solid @c-blue;
        border-bottom-color: transparent;
        background: @c-blue;
        color: #fff;
      }
    }
  }
}

form[role=login] {
  background: #fff;
  padding: 20px 35px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  h2 {
    color: @c-white;
  }
  span.glyphicon {
    color: #b0b0b0;
  }
  input, button {
    font-size: 14px;
  }
  input {
    color: @c-white;
  }
  input::-webkit-input-placeholder,
  input:-moz-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder {
    color: #c1c4c5;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    border: none;
    span.glyphicon {
      color: #fff;
    }
  }
  .btn-container {
    margin-top: 20px;
    .btn {
      width: 118px;
      margin: 0 3px 5px;
      span.glyphicon {
        color: #fff;
        margin-right: 5px;
      }
    }
  }
}

.login-form {
  max-width: 420px;
  margin: 100px auto;
  h2 {
    margin-bottom: 20px;
  }
  .input-group {
    margin-bottom: 15px;
  }
  .form-control {
    + .glyphicon {
      position: absolute;
      left: 0;
      top: 24%;
      padding: 8px 0 0 27px;
    }
  }
  .btn {
    margin-top: 5px;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
body {
  background: url("/images/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-size: 14px;
  line-height: 1.6em;
}
.content-container {
  max-width: 80%;
  margin: 20px auto;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.content-container h1 {
  margin-bottom: 20px;
}
.content-container .nav-tabs {
  border-bottom: 1px solid #428bca;
  margin: 10px 0;
}
.content-container .nav-tabs > li > a:hover {
  border-color: #eee #eee #428bca;
}
.content-container .nav-tabs > li.active a,
.content-container .nav-tabs > li.active a:hover,
.content-container .nav-tabs > li.active a:focus {
  border: 1px solid #428bca;
  border-bottom-color: transparent;
  background: #428bca;
  color: #fff;
}
form[role=login] {
  background: #fff;
  padding: 20px 35px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
form[role=login] h2 {
  color: #000;
}
form[role=login] span.glyphicon {
  color: #b0b0b0;
}
form[role=login] input,
form[role=login] button {
  font-size: 14px;
}
form[role=login] input {
  color: #000;
}
form[role=login] input::-webkit-input-placeholder,
form[role=login] input:-moz-placeholder,
form[role=login] input::-moz-placeholder,
form[role=login] input:-ms-input-placeholder {
  color: #c1c4c5;
}
form[role=login] button {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  border: none;
}
form[role=login] button span.glyphicon {
  color: #fff;
}
form[role=login] .btn-container {
  margin-top: 20px;
}
form[role=login] .btn-container .btn {
  width: 118px;
  margin: 0 3px 5px;
}
form[role=login] .btn-container .btn span.glyphicon {
  color: #fff;
  margin-right: 5px;
}
.login-form {
  max-width: 420px;
  margin: 100px auto;
}
.login-form h2 {
  margin-bottom: 20px;
}
.login-form .input-group {
  margin-bottom: 15px;
}
.login-form .form-control + .glyphicon {
  position: absolute;
  left: 0;
  top: 24%;
  padding: 8px 0 0 27px;
}
.login-form .btn {
  margin-top: 5px;
}
